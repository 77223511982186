@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';
@import '~@scentregroup/ui/tools/dirg.module.scss';
@import '~@scentregroup/ui/tools/dirg-breakpoint.module.scss';
@import '../../assets/sass/mixins.module.scss';

.moduleGroup {
  margin: 0;
}

.moduleListSection,
.moduleListFeature {
  margin-top: 0;
}

.moduleListFeature {
  padding-bottom: 0;
}

.moduleCta {
  margin-right: units(1);
}

@include supports-angles {
  .moduleListFeature,
  .moduleListSection {
    margin-top: -7vw;
  }

  .moduleListFeature {
    padding-bottom: calc(7vw + #{units(3)});
  }
}

.fullWidthCtaOnMobile {
  @include breakpoint(phone) {
    width: 100%;
  }
}

.evolved {
  .moduleListFeature,
  .moduleListSection {
    padding: 0;
    margin: units(3) 0;

    @include fromBreakpoint(tablet) {
      margin: units(4) 0;
    }
  }

  .moduleListSection + .moduleListFeature {
    margin-top: units(1.5);
  }

  .pageHeading {
    margin: 0;
  }
}
