@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';
@import '~@scentregroup/ui/tools/dirg.module.scss';
@import '~@scentregroup/ui/tools/dirg-breakpoint.module.scss';
@import '~@scentregroup/ui/tools/color.module.scss';
@import '../deck-scroll-wrapper/variables.module.scss';

.Article {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.superHeading {
  color: color('Brand');
  text-transform: uppercase;
}

.subHeading,
.times,
.location,
.body {
  animation: appear 1.5s 1 forwards;
}

.times {
  composes: bodyFont from '@scentregroup/ui/tools/typography.module.scss';
  color: color('Black');
  margin-top: units(1.5);
}

.location {
  composes: heading from '@scentregroup/ui/tools/typography.module.scss';
  margin-top: units(1);
  text-transform: uppercase;
  width: 100%;
}

.body {
  margin-top: units(1);
  width: 100%;

  > * + * {
    margin-top: units(1);
  }
}

.location + .body {
  margin-top: units(1.5);
}

@keyframes appear {
  from {
    opacity: 0.001;
  }

  to {
    opacity: 0.999;
  }
}

@keyframes draw-line {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

.containFlexChildrenForIE11 {
  width: 100%;
}

.ctaWrapper {
  margin-top: units(0.5);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cta {
  margin-bottom: units(1);

  @include fromBreakpoint('phablet') {
    width: 49%;
  }
}

.warningNotification {
  padding: 10px 0;
  margin: 0;
}

.evolved {
  &.halfContext {
    .superHeading {
      margin-top: units(1);
    }
  }

  .body {
    margin-top: units(0.5);
  }
}

.link {
  color: inherit;
  text-decoration: none;
}
