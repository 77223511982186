@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';
@import 'tools/dirg.module.scss';
@import 'tools/dirg-breakpoint.module.scss';
@import 'tools/color.module.scss';

.icon {
  width: units(4);
}

.iconWrapper {
  width: columns(1.5);
  height: columns(1.5);
  border-radius: 50%;
  padding: units(1);
  text-align: center;
  background-color: color('White');
  margin-bottom: units(-3);

  // this is a local z-index to bring the icon above the card
  z-index: 1;
}

.card {
  width: 100%;
  padding-top: units(3);
  text-align: center;
}

.childrenWrapper {
  > * {
    width: 100%;
    margin-top: units(1.5);
  }
}

.utilityBox {
  > * {
    padding: units(0.3);
  }
}

.utilityLinkText {
  color: color('Black');
  text-decoration-line: underline;
  font-weight: bold;
}

.dotPoints {
  > * + * {
    margin-top: units(1);
    padding: 0 units(3);
  }
}

.image {
  padding: units(1);
  max-width: 100%;
}
