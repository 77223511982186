@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';
@import '~@scentregroup/ui/tools/color.module.scss';
@import '~@scentregroup/ui/tools/dirg.module.scss';
@import '~@scentregroup/ui/tools/dirg-breakpoint.module.scss';
@import '../../assets/sass/mixins.module.scss';

$angleHeight: 7vw;

.skipTopPadding {
  padding-top: 0 !important;
}

.Group {
  padding: units(3) 0 units(3) 0;

  @include breakpoint('tablet') {
    padding: units(1.5) 0 units(1.5) 0;
  }

  @include supports-angles {
    padding: calc(#{$angleHeight} + #{units(3)}) 0;

    @include breakpoint('tablet') {
      padding: calc(#{$angleHeight} + #{units(1.5)}) 0;
    }
  }
}

.Group > * + * {
  margin-bottom: columns(1);
  margin-top: columns(1);

  @include breakpoint('phablet') {
    margin-bottom: units(2);
    margin-top: units(2);
  }
}

.backgroundImage {
  background-size: cover;
}

.evolved {
  &.Group {
    padding: 0;
    margin: units(3) 0;

    @include fromBreakpoint(tablet) {
      margin: units(4) 0;
    }
  }
}
