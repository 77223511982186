@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';
@import '~@scentregroup/ui/tools/dirg.module.scss';
@import '~@scentregroup/ui/tools/dirg-breakpoint.module.scss';

.FlipFlop {
  > * {
    flex: 1 1;
  }

  > * + * {
    padding: 0;
    margin-left: units(1.5);

    @include breakpoint(tablet) {
      padding: units(1) 0;
      margin-left: 0;
    }
  }
}

.right {
  flex-direction: row;
}

.left {
  flex-direction: row-reverse;

  > * + * {
    margin-left: 0;
  }

  > *:first-child {
    margin-left: units(1.5);

    @include breakpoint(tablet) {
      padding: units(1) 0;
      margin-left: 0;
    }
  }
}

.image {
  animation: appear 0.35s 1 forwards;
}

@keyframes appear {
  from {
    opacity: 0.001;
  }

  to {
    opacity: 0.999;
  }
}
