@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';
@import '~@scentregroup/ui/tools/color.module.scss';
@import '~@scentregroup/ui/tools/dirg.module.scss';
@import '~@scentregroup/ui/tools/dirg-breakpoint.module.scss';
@import '../../assets/sass/mixins.module.scss';
@import '../deck-scroll-wrapper/variables.module.scss';

.deckContainer {
  @include fromBreakpoint($grid-breakpoint-desktop) {
    padding: 0;
  }
}

.heading {
  margin: 0;
}

.contentWrapper {
  margin: units(1) -#{$deck-padding-phone};

  @include fromBreakpoint(tablet) {
    margin: units(1) -#{$deck-padding-desktop};
  }

  @include fromBreakpoint($grid-breakpoint-desktop) {
    margin: units(1) -#{$deck-padding-grid-breakpoint-desktop};
  }
}

.featuredContentScroll {
  padding-left: units(1.5);
  padding-right: units(1.5);

  @include fromBreakpoint(tablet) {
    padding-left: units(3);
    padding-right: units(3);
  }

  @include fromBreakpoint($grid-breakpoint-desktop) {
    padding-left: 0;
    padding-right: 0;
  }
}

.deckCardList {
  display: flex;

  // Include a dummy flex item to fake padding-right within the container:
  // https://itnext.io/horizontal-overflow-with-flexbox-css-64f530495303
  &::after {
    content: '';
    flex: 0 0 auto;
    width: $deck-padding-phone;

    @include fromBreakpoint(tablet) {
      width: $deck-padding-desktop;
    }

    @include fromBreakpoint($grid-breakpoint-desktop) {
      display: none;
    }
  }
}

.deckCard {
  flex-shrink: 0;
  border: 1px solid color('Grey');
  width: grid-item-width($grid-columns-phone);

  @include scroll-snap-align(start);

  @include fromBreakpoint($grid-breakpoint-phone) {
    width: grid-item-width($grid-columns-phablet);
  }

  @include fromBreakpoint(phablet) {
    width: grid-item-width($grid-columns-tablet);
  }

  @include fromBreakpoint(tablet) {
    width: grid-item-width($grid-columns-desktop);
  }

  & + & {
    margin-left: $grid-gap;
  }
}

.evolved {
  .heading {
    text-transform: none;
    color: color('Black');

    @include breakpoint(phablet) {
      font-size: font-scale(2);
    }
  }

  .deckCardList {
    &::after {
      width: $deck-padding-evolved-phone;

      @include fromBreakpoint(phablet) {
        display: none;
      }
    }
  }

  .deckCard {
    border: 0;
    width: grid-item-width-evolved($grid-columns-phone);

    @include scroll-snap-align(start);

    @include fromBreakpoint($grid-breakpoint-phone) {
      width: grid-item-width-evolved($grid-columns-phablet);
    }

    @include fromBreakpoint(phablet) {
      width: grid-item-width-evolved($grid-columns-tablet);
    }

    @include fromBreakpoint(tablet) {
      width: grid-item-width-evolved($grid-columns-desktop);
    }
  }

  .deckCard + .deckCard {
    margin-left: $grid-gap-evolved;
  }
}
